import { NavLink, useSearchParams } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';

import { isEmpty } from 'lodash';
import { format, isValid } from 'date-fns';
import {
  Box,
  CircularProgress,
  Collapse,
  Pagination,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { Error, Home } from '@mui/icons-material';
import Http from '@/services/http';
import { IUser } from '@/models/user-model';
import { IPagination } from '@/models/pagination-model';
import { IBase } from '@/models/base-model';
import { getLengthLabel } from '@/helpers/get-length-label';
import Header from '@/components/layouts/header';
import InfoLabel from '@/components/Info/label';

import RifPendingConfirmationsFilterForm from '../../components/rif-pending-confirmations-filter-form';

const ITEMS_PER_PAGE = 50;

interface IPendingConfirmationUser extends IUser {
  relatedRifs: {
    _id: string;
    code: string;
  }[];
}

const RifPendingConfirmations: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [loading, setLoading] = useState(false);
  const [filterLoading, setFilterLoading] = useState(false);

  const [baseOptions, setBaseOptions] = useState<any[]>([]);
  const [users, setUsers] = useState<IUser[]>([]);

  const [pendingMechanics, setPendingMechanics] = useState<
    IPagination<IPendingConfirmationUser>
  >({
    docs: [],
    limit: 0,
    offset: 0,
    totalDocs: 0,
    totalPages: 0,
    hasNextPage: false,
    hasPrevPage: false,
    pagingCounter: 0,
  });

  const submitFilter = useCallback(
    async (values: Record<string, any>) => {
      Object.entries(values).forEach(([key, value]) => {
        let queryValue;
        if (value instanceof Date) {
          const date = format(value, 'yyyy-MM-dd');
          if (isValid(value)) {
            queryValue = date;
          }
        } else if (!isEmpty(value)) {
          queryValue = value;
          if (typeof value === 'object' && value.id) {
            queryValue = value.id;
          }
        }
        if (queryValue) {
          searchParams.set(key, queryValue);
        } else {
          searchParams.delete(key);
        }
      });

      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const fetchBase = useCallback(async () => {
    const { status, data } = await Http.get<IPagination<IBase>>('bases', {
      params: {
        pagination: false,
      },
    });

    if (status === 200) {
      setBaseOptions(
        data.docs.map((b: any) => ({
          id: b._id,
          label: `${b.name} (${b.code})`,
        }))
      );
    }
  }, []);

  const fetchPendingMechanics = useCallback(async () => {
    const { status, data } = await Http.get<
      IPagination<IPendingConfirmationUser>
    >('rifs/pending-confirmations', {
      params: {
        ...Object.fromEntries(searchParams),
        limit: ITEMS_PER_PAGE,
      },
    });
    if (status === 200) {
      setPendingMechanics(data);
    }
  }, [searchParams]);

  const fetchUsers = useCallback(async () => {
    const roles = ['MECÂNICO', 'APOIO', 'AUDITOR', 'AVALIADOR'];
    const { data } = await Http.get(
      `fast-tracking-users?role=${roles.join('&&role=')}&pagination=false`
    );
    setUsers(data?.docs || []);
  }, []);

  useEffect(() => {
    setLoading(true);
    fetchUsers().then(() => setLoading(false));
    fetchPendingMechanics().then(() => setLoading(false));
    fetchBase().then(() => setFilterLoading(false));
  }, [fetchPendingMechanics, fetchBase, fetchUsers]);

  return (
    <Stack spacing={1}>
      <Header
        breadcrumbs={[
          {
            icon: Home,
          },
          {
            text: 'RIF',
          },
          {
            text: 'Confirmações Pendentes',
          },
        ]}
        title="Confirmações Pendentes"
      />
      <Paper sx={{ width: '100%' }}>
        <RifPendingConfirmationsFilterForm
          defaultBases={baseOptions}
          defaultUsers={users}
          defaultValues={{
            colaborator: searchParams.get('colaborator'),
            base: searchParams.get('base'),
            startDate: searchParams.get('startDate'),
            endDate: searchParams.get('endDate'),
          }}
          loading={filterLoading}
          onSubmit={async (values) => {
            submitFilter(values);
          }}
        />
      </Paper>
      <Box
        sx={{
          width: '100%',
          textAlign: 'right',
          padding: '0 15px',
        }}
      >
        {pendingMechanics.docs.length > 0 && (
          <Typography sx={{ fontSize: 14 }} variant="caption">
            {getLengthLabel(pendingMechanics.totalDocs, {
              pluralEntityName: 'documentos encontrados',
              uniqueEntityName: 'documento encontrado',
              emptyLabel: 'Nenhum documento encontrado',
            })}
            {pendingMechanics.totalPages > 1
              ? ` (${pendingMechanics.limit} por página)`
              : null}
          </Typography>
        )}
      </Box>
      <Paper sx={{ width: '100%' }}>
        <Collapse in={loading} unmountOnExit>
          <Paper
            elevation={0}
            sx={{
              alignItems: 'center',
              bgcolor: 'grey.100',
              display: 'flex',
              justifyContent: 'center',
              p: 4,
            }}
          >
            <CircularProgress color="secondary" />
          </Paper>
        </Collapse>
        <Collapse in={!loading && pendingMechanics.docs.length === 0}>
          <Stack
            alignItems="center"
            justifyContent="center"
            spacing={2}
            sx={{ p: 2 }}
          >
            <Typography variant="caption">
              <Error sx={{ fontSize: '56px' }} />
            </Typography>
            <Typography color="GrayText" variant="h5">
              Nenhum usuário encontrado
            </Typography>
          </Stack>
        </Collapse>
        <Collapse
          in={!loading || pendingMechanics.docs.length > 0}
          unmountOnExit
        >
          <TableContainer sx={{ overflowX: 'auto' }}>
            <Table stickyHeader sx={{ maxWidth: '100%', whiteSpace: 'nowrap' }}>
              <TableBody>
                {pendingMechanics.docs?.map((mechanic) => (
                  <TableRow key={mechanic._id}>
                    <TableCell>
                      <InfoLabel
                        items={[
                          {
                            title: 'Nome',
                            value: mechanic.name || '-',
                          },
                          {
                            title: 'Email',
                            value: mechanic.email || '-',
                          },
                        ]}
                      />
                    </TableCell>
                    <TableCell>
                      <InfoLabel
                        items={[
                          {
                            title: 'Registro',
                            value: mechanic.registration || '-',
                          },
                          {
                            title: 'RIF(s)',
                            value: (
                              <>
                                {mechanic.relatedRifs.map((i) => (
                                  <NavLink
                                    key={i._id}
                                    to={`/rif/management/${i._id}`}
                                  >
                                    {i.code}
                                  </NavLink>
                                ))}
                              </>
                            ),
                          },
                        ]}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Collapse>
        {pendingMechanics.totalPages > 1 && (
          <Box sx={{ m: 2 }}>
            <Pagination
              count={pendingMechanics.totalPages}
              onChange={(_, selectedPage) =>
                submitFilter({ page: selectedPage.toString() })
              }
              page={pendingMechanics.offset}
            />
          </Box>
        )}
      </Paper>
    </Stack>
  );
};
export default RifPendingConfirmations;
